<template>
  <div>
    <MemberTabbedLogin
      v-if="public_data"
      class="mx-auto mt-8"
      :company_id="public_data.company_id"
    />
  </div>
</template>

<style lang="scss" scoped>

</style>

<script> 


import axios from 'axios';
import { mapGetters } from 'vuex';
import MemberTabbedLogin from '@/view/pages/members/auth/login/MemberTabbedLogin.vue';

export default {
  name: 'ManageMembership',
  components: {
    MemberTabbedLogin
  },
  data() {
    return {
      public_data: null
    };
  },
  
  async mounted() {
    await this.fetch_public();
  },
  methods: {
    async fetch_public() {
      const res = await axios.get(`/system/public`);

      if (res.status === 200) {
        this.public_data = res.data;
      }
    },
  },
  computed: {
    ...mapGetters(['currentMember'])
  }
};
</script>
